<template>
  <iframe
    v-if="src"
    :src="src"
    frameborder="0"
  />
</template>

<script>
export default {
  name: 'MFrame',
  props: {
    src: {
      type: String,
      default: () => ''
    }
  },
  mounted() {
    setTimeout(() => {
      this.$emit('loaded')
    }, 1000)
  }
}
</script>

<style lang="sass" scoped>
  iframe
    width: 100%
    height: 99%
</style>
